class User {
    
    constructor(name, token, expire, ttl) {
        this.name = name
        this.token = token
        this.expire = expire
        this.ttl = ttl
	}

    getName() {
        return this.name
    }

    getToken() {
        return this.token
    }

    static loadFromLocalStorage() {
		const data = JSON.parse(window.localStorage.getItem("MusicApi:user"))
		return new User(data.name, data.token, data.expire, data.ttl);
	}

}

export default User; 
