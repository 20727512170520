import React, { useState, useEffect } from "react";
import ProgressRing from "./ProgressRing";
import UploadUtils from "./UploadUtils";
import User from "./User";
import styles from "./DragAndDrop.css.js";

const validExt = [
  ".mp3",
  ".mkv",
  ".mp4",
  ".avi",
  ".webm",
  ".mpg",
  ".vob",
  ".mov",
  ".zip",
];

let globalTick = 0;
const DragAndDrop = (props) => {
  const [isDrag, setDrag] = useState(false);
  const [isUpload, setUpload] = useState(false);
  const [files, setFiles] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [tick, setTick] = useState(globalTick);
  let dragDropCounter = 0;

  const onDragEnter = (e) => {
    dragDropCounter++;
    e.preventDefault();
    e.stopPropagation();
    setDrag(true);
  };

  // Mouse out from browser window
  const onDragLeave = (e) => {
    dragDropCounter--;
    e.preventDefault();
    e.stopPropagation();
    if (dragDropCounter <= 0) {
      setDrag(false);
    }
  };

  const onDrop = (e) => {
    // Local leave
    dragDropCounter = 0;
    e.preventDefault();
    e.stopPropagation();
    const utils = new UploadUtils("/upload");
    utils.setAuthentication(
      "X-AUTH-TOKEN",
      User.loadFromLocalStorage().getToken()
    );
    utils
      .onAddFile((e) => {
        const ext = "." + e.name.split(".").pop().toLowerCase();
        if (validExt.indexOf(ext) < 0) {
          console.log(`Can't upload ${ext} file`);
          return false;
        }
        files.push({
          id: e.id,
          name: e.name,
          fullName: e.fullName,
          progress: 0,
          isUpload: false,
        });
        setFiles(files);
        setUpload(files.length > 0);
      })
      .onAddFileDone((e) => {
        utils.upload(); // StartUpload
      })
      .onUploadStart((e) => {
        console.log("START UPLOAD FOR ", e.fullName);
        let idx = files.findIndex((item) => item.id === e.id);
        files[idx].isUpload = true;
        globalTick++;
        setTick(globalTick); // Force update event (since files is constant)
      })
      .onUploadEnd((e) => {
        console.log("END UPLOAD FOR ", e.fullName);
        // Remove from list
        let idx = files.findIndex((item) => item.id === e.id);
        files.splice(idx, 1);
        setFiles(files);
        globalTick++;
        setTick(globalTick); // Force update event (since files is constant)
        setUpload(files.length > 0);
      })
      .onUploadError((e) => {
        console.log("UPLOAD ERROR FOR ", e.fullName);
      })
      .onUploadChunk((e, percent) => {
        let idx = files.findIndex((item) => item.id === e.id);
        files[idx].progress = Math.round(percent);
        globalTick++;
        setTick(globalTick); // Force update event (since files is constant)
      })
      .addFiles(e.dataTransfer.items);
    setDrag(false);
  };

  useEffect(() => {
    // Prevent global events and send to component event
    window.addEventListener("dragstart", (e) => {
      e.preventDefault();
    });
    window.addEventListener("dragover", (e) => {
      e.preventDefault();
    });
    window.addEventListener("dragenter", (e) => {
      e.preventDefault();
      onDragEnter(e);
    });

    // cleanup this component
    return () => {
      //window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isDrag, onDragEnter]);

  let fileList = files.map((file) => (
    <div
      key={file.id}
      title={file.fullName.substring(1)}
      style={styles.uploadListItem}
    >
      <ProgressRing
        progress={file.progress}
        size="20"
        style={styles.uploadListItemProgress}
      ></ProgressRing>
      <label style={styles.uploadListItemLabel}>{file.name}</label>
    </div>
  ));

  if (!isDrag && !isUpload) {
    return "";
  }

  return (
    <>
      {isDrag && (
        <div
          onDragEnter={(e) => onDragEnter(e)}
          onDragLeave={(e) => onDragLeave(e)}
          onDrop={(e) => onDrop(e)}
          style={styles.root}
        >
          {/*<input style={styles.inputFile} type="file" id="filepicker" name="fileList" onChange={e => onChange(e)} directory="" webkitdirectory="" multiple /> */}
          <div style={styles.transparency}></div>
          <div style={styles.border}>
            <label style={styles.text} className="btn btn-primary active">
              Drag file here to upload
            </label>
          </div>
        </div>
      )}
      {isUpload && (
        <div style={styles.uploadRoot}>
          <h4 style={styles.uploadTitle}>
            Upload
            <button type="button" className="close">
              <span style={styles.uploadCloseIcon}>&times;</span>
            </button>
          </h4>
          <div style={styles.uploadList}>{fileList}</div>
        </div>
      )}
    </>
  );
};

export default DragAndDrop;
