import React, { useState } from "react";
import PlaylistEditorSong from "./PlaylistEditorSong";
import Ajax from "./AjaxUtils";
import styles from "./PlaylistEditor.css.js";

const PlaylistEditor = (props) => {
  const [songs, setSongs] = useState([]);
  const [changed, setChanged] = useState(false);
  const [selectedUuid, setSelectedUuid] = useState(null);
  const [playlistName, setPlaylistName] = useState("");
  const [playlistUuid, setPlaylistUuid] = useState("");

  /*const isAdmin = JSON.parse(window.localStorage.getItem("MusicApi:user"))[
    "is_admin"
  ];*/

  const parseDuration = (duration) => {
    const parts = duration.split(":");
    return parseInt(parts[0]) * 60 + parseInt(parts[1]);
  };

  const handlePlaylistNameChange = (event) => {
    setPlaylistName(event.target.value);
  };

  const handleMessage = async (event) => {
    if (event.data.component !== "PlaylistEditor") {
      return;
    }
    switch (event.data.type) {
      // Playlist changed (from outside)
      case "playlistChange":
        setChanged(false);
        setPlaylistUuid(event.data.data.uuid);
        if (event.data.data.uuid === "") {
          setPlaylistName("");
          setSongs([]);
          return;
        }
        setPlaylistName(event.data.data.name);
        const data = await Ajax.get(
          `/api/media/music/playlist/${event.data.data.uuid}`,
          { random_order: false }
        );

        if (!data.files || data.files.length === 0) {
          setSongs([]);
          return;
        }
        const newSongs = data.files.map((song) => {
          return {
            has_cover: song.has_cover,
            uuid: song.uuid,
            artist: song.artist,
            name: song.name,
            popularity: song.popularity,
            duration: song.duration,
          };
        });
        setSongs(newSongs);

        break;
      // Add a new dong to playlist (from outside)
      case "playlistAddSong":
        setChanged(true);
        setSongs((songs) => [
          ...songs,
          {
            has_cover: event.data.data.has_cover,
            uuid: event.data.data.uuid,
            artist: event.data.data.artist,
            name: event.data.data.name,
            popularity: event.data.data.popularity,
            duration: event.data.data.duration,
          },
        ]);
        break;
      default:
        console.error(
          `Unhandled message ${event.data.type} for ${event.data.component}`,
          event.data.data
        );
    }
  };

  const handleRemoveSongFromPlaylist = () => {
    const selIndex = songs.findIndex((song) => song?.uuid === selectedUuid);
    if (selIndex < 0) {
      return;
    }
    songs.splice(selIndex, 1);
    console.log(songs.length, selIndex);
    setChanged(true);
    setSongs([...songs]);
    if (songs.length > selIndex) {
      setSelectedUuid(songs[selIndex].uuid);
    } else if (songs.length > 0) {
      setSelectedUuid(songs[songs.length - 1].uuid);
    } else {
      setSelectedUuid("");
    }
  };

  const handleMoveSongUp = () => {
    const selIndex = songs.findIndex((song) => song?.uuid === selectedUuid);
    if (selIndex < 1) {
      return;
    }
    const tmp = songs[selIndex - 1];
    songs[selIndex - 1] = songs[selIndex];
    songs[selIndex] = tmp;
    setChanged(true);
    setSongs([...songs]);
  };

  const handleMoveSongDown = () => {
    const selIndex = songs.findIndex((song) => song?.uuid === selectedUuid);
    if (selIndex < 0 || selIndex >= songs.length - 1) {
      return;
    }
    console.log(selIndex, songs.length);
    const tmp = songs[selIndex + 1];
    songs[selIndex + 1] = songs[selIndex];
    songs[selIndex] = tmp;
    setChanged(true);
    setSongs([...songs]);
  };

  const handleSavePlaylist = async (event) => {
    const songList = songs.map((song) => {
      return song.uuid;
    });
    let status;
    if (playlistUuid === "") {
      status = await Ajax.post(`/api/media/music/playlist`, {
        name: playlistName,
        private: false,
        songs: songList,
      });
    } else {
      status = await Ajax.put(`/api/media/music/playlist/${playlistUuid}`, {
        name: playlistName,
        private: false,
        songs: songList,
      });
    }

    if (status.exception) {
      alert(status.exception);
      return;
    }
    setPlaylistUuid(status.data.uuid);
    setPlaylistName(status.data.name);
    props.onEvent("savePlaylist", {
      uuid: playlistUuid,
    });
  };

  const handleDeletePlaylist = async (event) => {
    let status;
    if (playlistUuid === "") {
      return;
    } else {
      if (!window.confirm("Are you sure?")) {
        return;
      }
      status = await Ajax.delete(`/api/media/music/playlist/${playlistUuid}`);
    }
    if (status.exception) {
      alert(status.exception);
      return;
    }
    setPlaylistName("");
    setPlaylistUuid("");
    setSongs([]);
    props.onEvent("deletePlaylist", {
      uuid: playlistUuid,
    });
  };

  React.useEffect(() => {
    window.addEventListener("message", handleMessage);
    // cleanup this component
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  let totTime = 0;
  let totSongs = 0;
  return (
    <div style={styles.container}>
      <div>
        <input
          type="text"
          value={playlistName}
          className="form-control"
          placeholder="Playlist name"
          style={styles.playlistName}
          onChange={handlePlaylistNameChange}
        ></input>
        <button
          className="btn btn-outline-primary mr-sm-2"
          type="button"
          style={styles.actionButton}
          onClick={handleSavePlaylist}
        >
          <i className="fa fa-floppy-o"></i>
        </button>
        <button
          className="btn btn-outline-primary mr-sm-2"
          type="button"
          style={styles.actionButton}
          onClick={handleDeletePlaylist}
        >
          <i className="fa fa-trash"></i>
        </button>
      </div>
      <div style={styles.listContainer}>
        <div style={styles.itemsContainer}>
          {songs.map((song, seq) => {
            totSongs++;
            totTime += parseDuration(song.duration);
            const selected = selectedUuid === song.uuid;
            return (
              <PlaylistEditorSong
                key={`${song.uuid}-${seq}`}
                song={song}
                selected={selected}
                onClick={() => {
                  props.onEvent("clickSong", {
                    uuid: song.uuid,
                  });
                }}
                onSelect={() => {
                  setSelectedUuid(song.uuid);
                }}
              ></PlaylistEditorSong>
            );
          })}
        </div>
        <div style={styles.actionItemsContainer}>
          <div style={styles.actionItemsSubContainer}>
            <button
              className="btn btn-outline-primary mr-sm-2"
              type="button"
              onClick={handleMoveSongUp}
            >
              <i className="fa fa-arrow-up"></i>
            </button>
            <button
              className="btn btn-outline-primary mr-sm-2"
              type="button"
              style={styles.removeSongFromPlaylistButton}
              onClick={handleRemoveSongFromPlaylist}
            >
              <i className="fa fa-times"></i>
            </button>
            <button
              className="btn btn-outline-primary mr-sm-2"
              type="button"
              onClick={handleMoveSongDown}
            >
              <i className="fa fa-arrow-down"></i>
            </button>
          </div>
        </div>
      </div>
      <div style={styles.summaryContainer}>
        {totSongs} songs - {Math.floor(totTime / 60)}:{totTime % 60}
      </div>
    </div>
  );
};

export default PlaylistEditor;
