const styles = {
  /* Drag & drop area*/
  root: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 10001,
  },

  inputFile: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 10002,
    border: "10px solid red",
  },

  border: {
    border: "3px dashed #06c",
    borderRadius: "10px",
    top: "5px",
    left: "5px",
    width: "calc(100% - 10px)",
    height: "calc(100% - 10px)",
    position: "relative",
  },
  transparency: {
    border: "30px dashed #r00",
    borderRadius: "10px",
    top: "5px",
    left: "5px",
    width: "calc(100% - 10px)",
    height: "calc(100% - 10px)",
    position: "absolute",
    opacity: 0.4,
    background: "#fff",
  },
  text: {
    position: "relative",
    top: "calc(100% - 50px)",
    left: "50px",
    width: "calc(100% - 100px)",
  },
  /* Upload window */
  uploadRoot: {
    bottom: "25px",
    right: "25px",
    width: "250px",
    height: "300px",
    position: "absolute",
    zIndex: 10000,
    border: "2px solid #005cbf",
    borderRadius: "5px",
  },
  uploadTitle: {
    color: "#fff",
    border: "1px solid #005cbf",
    background: "#0062cc",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    textAlign: "center",
    margin: "-1px",
    height: "30px",
  },
  uploadCloseIcon: {
    color: "#fff",
    textShadow: "0 1px 0 #000",
    marginRight: "4px",
  },
  uploadList: {
    background: "#fff",
    width: "100%",
    height: "calc(100% - 28px)",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
  },
  uploadListItem: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  uploadListItemProgress: {},
  uploadListItemLabel: {
    flex: "1 0",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: 0,
  },
};

export default styles;
