const Ajax = {
  _getToken: () => {
    const data = JSON.parse(window.localStorage.getItem("MusicApi:user"));
    return data["token"];
  },

  call: async (method, url, params, data) => {
    if (params) {
      const queryString = Object.keys(params)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
        )
        .join("&");
      url = `${url}?${queryString}`;
    }
    const options = {
      method: method,
      mode: "cors",
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, *same-origin, omit
      headers: {
        "X-AUTH-TOKEN": Ajax._getToken(),
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    };
    if (
      method !== "GET" &&
      data !== null &&
      data !== undefined &&
      Object.keys(data).length !== 0
    ) {
      options.body = JSON.stringify(data); // body data type must match "Content-Type" header
    }
    const response = await fetch(url, options);
    return await response.json();
  },

  get: async (url, params) => {
    return await Ajax.call("GET", url, params, null);
  },

  post: async (url, data, params) => {
    return await Ajax.call("POST", url, params, data);
  },

  put: async (url, data, params) => {
    return await Ajax.call("PUT", url, params, data);
  },

  delete: async (url, params) => {
    return await Ajax.call("DELETE", url, params);
  },
};

export default Ajax;
