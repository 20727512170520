const styles = {
  container: {
    height: "60px",
    padding: "6px 6px 6px 6px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    border: "1px solid rgba(0,0,0,.125)",
    borderRadius: "0.25rem",
    cursor: "pointer",
  },

  containerSelected: {
    backgroundColor: "#fff",
    color: "#0056b3",
  },

  cover: {
    width: "50px",
    height: "50px",
  },
  textContainer: {
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },

  duration: {
    width: "60px",
    textAlign: "right",
  },
};

export default styles;
