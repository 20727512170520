import React from "react";

const styles = {
  svg: {},
};
const ProgressRing = (props) => {
  const size = parseInt(props.size);
  const stroke = parseInt(props.stroke);
  const deg = Math.max(
    0,
    Math.min(360, parseFloat((props.progress / 100) * 360))
  );

  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  const describeArc = (x, y, radius, startAngle, endAngle) => {
    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    var d = [
      "M",
      start.x,
      start.y,
      "A",
      radius,
      radius,
      0,
      largeArcFlag,
      0,
      end.x,
      end.y,
    ].join(" ");
    return d;
  };

  const c = size / 2 - stroke / 2 + 1;

  return (
    <svg style={styles.svg} height={size} width={size}>
      <path
        fill="none"
        stroke={props.outColor}
        strokeWidth={stroke}
        d={describeArc(c, c, (size - stroke) / 2, 0.001, 360)}
      />
      <path
        fill="none"
        stroke={props.color}
        strokeWidth={stroke}
        d={describeArc(c, c, (size - stroke) / 2, 0.001, deg)}
      />
    </svg>
  );
};

ProgressRing.defaultProps = {
  size: "18",
  color: "#666",
  outColor: "#ccc",
  progress: "0",
  stroke: "3",
};

export default ProgressRing;
