class UploadItem {
  constructor(id, name, fullName) {
    this.id = id;
    this.name = name;
    this.fullName = fullName;
    this.file = null;
  }

  setFile = (file) => {
    this.file = file;
  };
}

class UploadUtils {
  constructor(url) {
    this.url = url;
    this.retry = 3;
    this.retryDelay = 5 * 1000;
    this.items = [];
    this.time = Date.now();
    this.chunkSize = 1 * 1024 * 1024; //256 * 1024
    this.authHeader = {};
    // Events callback
    this._onAddFile = (e) => {};
    this._onAddFileDone = (e) => {};
    this._onUploadStart = (e) => {};
    this._onUploadEnd = (e) => {};
    this._onUploadError = (e) => {};
    this._onUploadChunk = (e, progress) => {};

    this.dirCount = 0;
  }

  _hashCode = (s) => {
    return s.split("").reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  };

  // Drag of url or text
  // Just a log now
  _addStringItem = (item) => {
    if (item.type.match("^text/plain")) {
      item.getAsString((s) => {
        console.log("value =", s);
      });
    } else if (item.type.match("^text/html")) {
      // Drag data item is HTML
      console.log("... Drop: HTML");
    } else if (item.type.match("^text/uri-list")) {
      // Drag data item is URI
      console.log("... Drop: URI");
    }
  };

  // Return true if directory present
  _addFileOrDirectoryItem = (item) => {
    if (item.isFile) {
      const newItem = new UploadItem(
        this.time + "-" + Math.abs(this._hashCode(item.fullPath)),
        item.name,
        item.fullPath
      );
      if (this._onAddFile(newItem) !== false) {
        newItem.setFile(item);
        this.items.push(newItem);
      }
    } else if (item.isDirectory) {
      this.dirCount++;
      const directoryReader = item.createReader();
      directoryReader.readEntries((entries) => {
        entries.forEach((entry) => {
          this._addFileOrDirectoryItem(entry);
        });
        this.dirCount--;
        if (this.dirCount === 0) {
          // Async finish
          setTimeout(() => {
            this._onAddFileDone(this.items);
          }, 0);
        }
      });
    }
  };

  addFiles = (items) => {
    if (!items) {
      return this;
    }

    this.time = Date.now();
    let dirCount = 0;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item instanceof DataTransferItem) {
        // Drag&drop file or url
        if (item.kind === "string") {
          this._addStringItem(item);
        } else if (item.kind === "file") {
          this._addFileOrDirectoryItem(item.webkitGetAsEntry());
          if (this.dirCount > 0) {
            dirCount++;
          }
        }
      } else if (item instanceof File) {
        // Upload input
        console.log("input");
      }
    }
    if (dirCount === 0) {
      setTimeout(() => {
        this._onAddFileDone(this.items);
      }, 0);
    }
    return this;
  };

  _getFile = async (fileEntry) => {
    try {
      return await new Promise((resolve, reject) =>
        fileEntry.file(resolve, reject)
      );
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  _uploadFileChunk = async (file, uuid, chunkNo) => {
    let formData = new FormData();
    const start = chunkNo * this.chunkSize;
    const end = start + this.chunkSize;
    formData.append("file", file.slice(start, end));
    formData.append("uuid", uuid);
    formData.append("chunk", chunkNo + 1);

    const headers = new Headers();
    for (const [key, value] of Object.entries(this.authHeader)) {
      headers.set(key, value);
    }
    const response = await fetch(this.url, {
      method: "POST",
      body: formData,
      headers: headers,
    });
    return response.ok;
  };

  _sleep = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  /**
   * Start request
   * @param {*} data
   * @returns
   */
  _uploadFileBegin = async (data) => {
    const headers = new Headers({
      "Content-Type": "application/json",
    });
    for (const [key, value] of Object.entries(this.authHeader)) {
      headers.set(key, value);
    }
    const response = await fetch(`${this.url}/begin`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      return false;
    }
    return (await response.json())["uuid"];
  };

  /**
   * Close request
   * @param {*} data
   * @returns
   */
  _uploadFileCommit = async (uuid) => {
    const headers = new Headers({
      "Content-Type": "application/json",
    });
    for (const [key, value] of Object.entries(this.authHeader)) {
      headers.set(key, value);
    }
    const response = await fetch(`${this.url}/commit`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ uuid: uuid }),
    });
    if (!response.ok) {
      return false;
    }
    return true;
  };

  _uploadFile = async (item) => {
    const file = await this._getFile(item.file);
    const totChunks = Math.ceil(file.size / this.chunkSize);
    let uuid = await this._uploadFileBegin({
      name: file.name,
      lastModified: file.lastModified, // TODO: Convert to ISO format
      size: file.size,
      type: file.type,
      chunks: totChunks,
    });
    if (!uuid) {
      return false;
    }
    let uploadDone = false;
    for (let chunkNo = 0; chunkNo < totChunks; chunkNo++) {
      for (let i = 0; i < this.retry; i++) {
        const ok = await this._uploadFileChunk(file, uuid, chunkNo);
        if (ok) {
          uploadDone = true;
          this._onUploadChunk(item, (chunkNo / totChunks) * 100);
          break;
        }
        uploadDone = false;
        console.log(`Retry #${i + 1} in ${this.retryDelay} ms`);
        await this._sleep(this.retryDelay);
      }
      if (!uploadDone) {
        return false;
      }
    }
    uploadDone = await this._uploadFileCommit(uuid);
    return uploadDone;
  };

  upload = async () => {
    while (this.items.length > 0) {
      this._onUploadStart(this.items[0]);
      this._onUploadChunk(this.items[0], 0);
      const ok = await this._uploadFile(this.items[0]);
      if (ok) {
        this._onUploadEnd(this.items[0]);
      } else {
        this._onUploadError(this.items[0]);
      }
      this.items.shift();
    }
    return this;
  };

  setAuthentication = (key, token) => {
    this.authHeader[key] = token;
    return this;
  };

  onAddFile = (callback) => {
    this._onAddFile = callback;
    return this;
  };

  onAddFileDone = (callback) => {
    this._onAddFileDone = callback;
    return this;
  };

  onUploadStart = (callback) => {
    this._onUploadStart = callback;
    return this;
  };

  onUploadEnd = (callback) => {
    this._onUploadEnd = callback;
    return this;
  };

  onUploadError = (callback) => {
    this._onUploadError = callback;
    return this;
  };

  onUploadChunk = (callback) => {
    this._onUploadChunk = callback;
    return this;
  };
}

export default UploadUtils;
