const styles = {
  /* Drag & drop area*/
  container: {
    height: "100%",
    width: "100%",
  },

  playlistName: {
    margin: "8px 10px 8px 8px",
    width: "calc(100% - 120px)",
    display: "inline",
  },

  actionButton: {
    marginTop: "-4px",
  },

  listContainer: {
    borderTop: "1px solid #868e96",
    borderBottom: "1px solid #868e96",
    height: "calc(100% - 80px)",
    color: "white",
    display: "flex",
  },

  itemsContainer: {
    borderRight: "1px solid #868e96",
    flex: "auto",
    overflowX: "hidden",
    overflowY: "auto",
  },

  actionItemsContainer: {
    color: "white",
    width: "55px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexWrap: "wrap",
  },

  removeSongFromPlaylistButton: {
    width: "40px",
    marginTop: "10px",
    marginBottom: "10px",
  },

  actionItemsSubContainer: {
    padding: "7px",
    width: "100%",
  },

  summartContainer: {
    height: "30px",
    verticalAlign: "middle",
  },
};

export default styles;
