import React from "react";
import ReactDOM from "react-dom";
import DragAndDrop from "./DragAndDrop";
import PlaylistEditor from "./PlaylistEditor";

if (document.getElementById("drag-and-drop-container")) {
  ReactDOM.render(
    <React.StrictMode>
      <DragAndDrop />
    </React.StrictMode>,
    document.getElementById("drag-and-drop-container")
  );
}

/*global reactPlaylistEvent*/
if (document.getElementById("playlist-editor-container")) {
  ReactDOM.render(
    <React.StrictMode>
      <PlaylistEditor
        onEvent={(eventName, props) => reactPlaylistEvent(eventName, props)}
      />
    </React.StrictMode>,
    document.getElementById("playlist-editor-container")
  );
}
