import React from "react";
import styles from "./PlaylistEditorSong.css.js";

const Popularity = (popularity) => {
  popularity = Math.floor(popularity.popularity / 10);
  if (popularity <= 4) {
    return (
      <>
        <i class="fa fa-star-o"></i>
        <i class="fa fa-star-o"></i>
        <i class="fa fa-star-o"></i>
      </>
    );
  }
  if (popularity <= 5) {
    return (
      <>
        <i class="fa fa-star-half-o"></i>
        <i class="fa fa-star-o"></i>
        <i class="fa fa-star-o"></i>
      </>
    );
  }
  if (popularity <= 6) {
    return (
      <>
        <i class="fa fa-star"></i>
        <i class="fa fa-star-o"></i>
        <i class="fa fa-star-o"></i>
      </>
    );
  }
  if (popularity <= 7) {
    return (
      <>
        <i class="fa fa-star"></i>
        <i class="fa fa-star-half-o"></i>
        <i class="fa fa-star-o"></i>
      </>
    );
  }
  if (popularity <= 8) {
    return (
      <>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star-o"></i>
      </>
    );
  }
  if (popularity <= 9) {
    return (
      <>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star-half-o"></i>
      </>
    );
  }
  return (
    <>
      <i class="fa fa-star"></i>
      <i class="fa fa-star"></i>
      <i class="fa fa-star"></i>
    </>
  );
};

const PlaylistEditorSong = (props) => {
  const song = props.song;
  const selected = props.selected;
  const nameTitle = song.name?.length > 20 ? song.name : null;
  const artistTitle = song.artist?.length > 30 ? song.artist : null;

  const coverUrl = song.has_cover
    ? `/cover/50x50/${song.uuid}.jpg`
    : "/images/music.png";

  let containerStyle = styles.container;
  if (selected) {
    containerStyle = Object.assign(
      {},
      styles.container,
      styles.containerSelected
    );
  }

  return (
    <div data-uuid={song.uuid} style={containerStyle} onClick={props.onSelect}>
      <img
        src={coverUrl}
        loading="lazy"
        alt=""
        style={styles.cover}
        onClick={(e) => {
          e.stopPropagation();
          props.onClick();
        }}
      ></img>
      <div style={styles.textContainer}>
        <div title={nameTitle}>{song.name}</div>
        <small title={artistTitle}>{song.artist}</small>
      </div>
      <div style={styles.duration}>
        <div>{song.duration}</div>
        <small>
          <Popularity popularity={song.popularity}></Popularity>
        </small>
      </div>
    </div>
  );
};

export default PlaylistEditorSong;
